import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import { ThemeProvider } from '../components/themeContext'
import "../styles/markdown.css"
import Seo from "../components/seo"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement();

export default function PortfolioPost({ data }) {
  const post = data.markdownRemark

  return (
    // <ThemeProvider>
    <div>
      <Seo title={post.frontmatter.title}></Seo>
      <div className="container flex flex-col mx-auto">
        <Header></Header>

        <div className="pt-20 px-5 py-10 mx-auto w-full sm:w-5/6 md:w-4/6 container flex flex-col items-center markdown">
          <h1 className="text-4xl font-medium text-center">{post.frontmatter.title}</h1>
          <p className="text-md font-medium text-gray-700 dark:text-gray-200 mb-16">{post.frontmatter.category}</p>
          {!!post.tableOfContents && (
            <div
              className="post-toc //fixed //top-40 //left-5 w-full pl-5"
              dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
            />
          )}
          <div className="order w-full" dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>

        <Footer></Footer>
      </div>
    </div>
    // </ThemeProvider>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: {slug: { eq: $slug } }) {
      html
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD YYYY")
        category
        description
      }
    }
  }
`